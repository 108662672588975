/// <reference types="typescript" />

interface IConfiguration {
	sliderAutoplay: boolean;
}

const configurationEl = document.getElementById('configuration-var');
const configuration: IConfiguration = JSON.parse(configurationEl.textContent || configurationEl.innerHTML);

$(() => {
	$.nette.init();

	$('.eu-cookies button').click(function () {
		const date = new Date();
		date.setFullYear(date.getFullYear() + 10);
		document.cookie = 'eu-cookies=1; path=/; expires=' + date.toUTCString();
		$('.eu-cookies').hide();
	});
});
